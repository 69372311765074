



























import {
  SfLink, SfList,
} from '@storefront-ui/vue';
import { defineComponent, ref, PropType, onMounted } from '@nuxtjs/composition-api';
import { CategoryTree } from '~/modules/GraphQL/types';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { useUiHelpers } from '~/composables';
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';


export default defineComponent({
  name: 'HeaderFixedSubCategory',
  components: {
    SfLink,
    SfList,
    HeaderNavigationSubcategories: () => import('~/components/Header/Navigation/HeaderNavigationSubcategories.vue'),
  },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
  },

  setup() {

    const { getCatLink } = useUiHelpers();

    const currentCategory = ref<CategoryTree | null>(null);
    const lvl0CatRefs = ref<ComponentTemplateRef[]>();
    const hasFocus = ref(false);
    let lvl0CatFocusIdx = 0;
    let focusedElement: HTMLElement | null = null;

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };

    const hasChildren = (category: CategoryTree) => Boolean(category?.children?.length);
    console.log("hasChildren", hasChildren);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentCategory(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
    };

    // const navCategoryTree = ref<CategoryTree[]>([]);

    // const { categoryAncestors: initialHistory, activeCategory, loadCategoryTree } = useTraverseCategory();
    // console.log("activeCategory",activeCategory.value);
    // console.log("categoryTree",categoryTree);
    onMounted(() => {


      // customNavCategoryLoad()
    });

    // const customNavCategoryLoad = async () => {
    // console.log("activeCategory",activeCategory);
    // navCategoryTree.value=activeCategory.value.children
    // }

    //     const currentCategory = ref<CategoryTree | null>(null);
    //         const setCurrentCategory = (category: CategoryTree | null) => {
    //   currentCategory.value = category;
    // };
    // const hasChildren = (category: CategoryTree) => Boolean(category?.children?.length);


    return {
      getCatLink,
      currentCategory,
      hasChildren,
      onMouseEnter,
      hasFocus,
      lvl0CatRefs,
      setCurrentCategory,
      setFocus,
      focusRootElementAndHideSubcategories,
      navRight,
      navLeft,


      // currentCategory,
      // customNavCategoryLoad,
    };
  },
  //     watch: {
  //     $route(to, from) {
  //       this.customNavCategoryLoad()
  //     }
  //   },
});
