






































































import '../assets/styles.scss';

import {
  SfOverlay, SfHeader, SfButton, SfBadge,
} from '@storefront-ui/vue';

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderFixedSubCategory from '~/components/Header/Navigation/HeaderFixedSubCategory.vue';

import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
  useUiState,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useTopBar } from './TopBar/useTopBar';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';

import MenuComponent from '~/modules/catalog/pages/category.vue';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';

export default defineComponent({
  components: {
    HeaderNavigation,
    HeaderFixedSubCategory,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () => import(
      /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal, toggleMobileMenu } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));
    const categoryTree = ref<CategoryTree[]>([]);
    const navCategoryTree = ref<CategoryTree[]>([]);
      const navCategoryTrees = ref<CategoryTree[]>([]);

    const { categoryAncestors: initialHistory, activeCategory, loadCategoryTree } = useTraverseCategory();

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
      customNavCategoryLoad()
    });

    const customNavCategoryLoad = async () => {
      const categoryFetching = useCategoryStore();
      if (categoryFetching.categories === null) {
        await categoryFetching.load();
      }
      //for specific category Showing
      // navCategoryTree.value = categoryFetching.categories.children[0].children.filter((item) => ['MTUx', 'MTUy'].includes(item.uid));

      navCategoryTree.value = categoryFetching.categories.children[0].children;
        // console.log("initialHistory",initialHistory.value.length >0);
        // if (!activeCategory?.value?.children) {
        //   'hi'
        // }else{
        //   'bye'
        // }
        if (initialHistory.value.length >0) {
          navCategoryTrees.value=initialHistory.value[1].children;
        }else{
          navCategoryTrees.value=categoryFetching.categories.children[0].children[0].children;
        }
      // if (!activeCategory?.value?.children) {
        // navCategoryTrees.value=activeCategory?.value?.children;
        // navCategoryTrees.value=initialHistory.value[1].children;

        // console.log("activeCategory",activeCategory.value)
        // console.log("initialHistory",initialHistory.value[1].children);
        
        // let newCategoryArray: any[] = [];
        // for (let i = 0; i < activeCategory?.value?.children.length; i++) {
        //   if (i > 3) {
        //     break;
        //   }
        //   const finalArrayItem = activeCategory?.value?.children[i];
        //   newCategoryArray.push(finalArrayItem);
        // }
        // navCategoryTree.value = newCategoryArray;
        // navCategoryTrees.value=categoryFetching.categories.children[0].children[0].children;
      // }
      // else{
      // }
      // navCategoryTrees.value=initialHistory.value[1].children;


      // let headerMenu: any
      // setTimeout(async function () {
      // headerMenu = await MenuComponent.methods.myMethod();


      // let value = 'MTUx';
      // navCategoryTree.value = categoryFetching.categories.children[0].children.filter((item) => ['MTUx','MTUy'].includes(item.uid));

      // navCategoryTree.value = activeCategory.value.children;

      // if(headerMenu.value){
      //   value = headerMenu.value;

      //   navCategoryTree.value = activeCategory.value.children;
      // }
      // }, 1000);

    }

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();

      if (categories.categories === null) {
        await categories.load();
      }
      toggleMobileMenu();
    };

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      navCategoryTree,
      navCategoryTrees,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      loadCategoryMenu,
      toggleMobileMenu,
      customNavCategoryLoad
    };
  },
  watch: {
    $route(to, from) {
      this.customNavCategoryLoad()
    }
  }
});
