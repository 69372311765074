import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _296f82b5 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _417d8e5e = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _ceb15912 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _6ff9147a = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _66b2c7ce = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78257916 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _61b344bd = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _8665c8ac = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _58c2b4e3 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _22f1ee8a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1ba897f8 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _674e806c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _f8429318 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _416bb178 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _29811390 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _21d68638 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _530036c4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _47b763b3 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _1d0bb296 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _526b59bb = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _3a41dfa0 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _79a49865 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/german",
    component: _296f82b5,
    name: "home___german"
  }, {
    path: "/index",
    component: _296f82b5,
    name: "home___index"
  }, {
    path: "/german/cart",
    component: _417d8e5e,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _ceb15912,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _6ff9147a,
      name: "billing___german"
    }, {
      path: "payment",
      component: _66b2c7ce,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _78257916,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _61b344bd,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _8665c8ac,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _58c2b4e3,
    name: "Cms___german"
  }, {
    path: "/german/customer",
    component: _22f1ee8a,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _1ba897f8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _674e806c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _f8429318,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _416bb178,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _29811390,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _21d68638,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _530036c4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _47b763b3,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d0bb296,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _526b59bb,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Faq",
    component: _3a41dfa0,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _296f82b5,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _79a49865,
    name: "Page___german"
  }, {
    path: "/index/cart",
    component: _417d8e5e,
    name: "cart___index"
  }, {
    path: "/index/checkout",
    component: _ceb15912,
    name: "checkout___index",
    children: [{
      path: "billing",
      component: _6ff9147a,
      name: "billing___index"
    }, {
      path: "payment",
      component: _66b2c7ce,
      name: "payment___index"
    }, {
      path: "shipping",
      component: _78257916,
      name: "shipping___index"
    }, {
      path: "thank-you",
      component: _61b344bd,
      name: "thank-you___index"
    }, {
      path: "user-account",
      component: _8665c8ac,
      name: "user-account___index"
    }]
  }, {
    path: "/index/Cms",
    component: _58c2b4e3,
    name: "Cms___index"
  }, {
    path: "/index/customer",
    component: _22f1ee8a,
    meta: {"titleLabel":"My Account"},
    name: "customer___index",
    children: [{
      path: "addresses-details",
      component: _1ba897f8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___index"
    }, {
      path: "my-newsletter",
      component: _674e806c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___index"
    }, {
      path: "my-profile",
      component: _f8429318,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___index"
    }, {
      path: "my-reviews",
      component: _416bb178,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___index"
    }, {
      path: "my-wishlist",
      component: _29811390,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___index"
    }, {
      path: "order-history",
      component: _21d68638,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___index"
    }, {
      path: "addresses-details/create",
      component: _530036c4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___index"
    }, {
      path: "/index/reset-password",
      component: _47b763b3,
      alias: "/customer/account/createPassword",
      name: "reset-password___index"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d0bb296,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___index"
    }, {
      path: "order-history/:orderId",
      component: _526b59bb,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___index"
    }]
  }, {
    path: "/index/Faq",
    component: _3a41dfa0,
    name: "Faq___index"
  }, {
    path: "/index/Home",
    component: _296f82b5,
    name: "Home___index"
  }, {
    path: "/index/Page",
    component: _79a49865,
    name: "Page___index"
  }, {
    path: "/german/:slug+",
    component: _79a49865,
    name: "page___german"
  }, {
    path: "/index/:slug+",
    component: _79a49865,
    name: "page___index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
